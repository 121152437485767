import React from 'react'
import BeersList from '../components/BeersList'
import { graphql } from 'gatsby'

function Beers({ data }) {
	const beers = data.allBeer.nodes
	return (
		<div>
			<BeersList beers={beers} />
		</div>
	)
}

export default Beers

export const query = graphql`
	query {
		allBeer {
			nodes {
				name
				price
				image
				id
				rating {
					average
					reviews
				}
			}
		}
	}
`
