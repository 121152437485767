import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const BeersStyles = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 2rem;
`

const BeerStyles = styled.div`
	border: 1px;
	padding: 2rem;
	text-align: center;
	img {
		width: 100%;
		width: 150px;
		object-fit: contain;
		display: block;
		display: grid;
		align-items: center;
		font-size: 10px;
	}
`

function SingleBeer({ beer }) {
	if (beer.image) {
		const rating = Math.round(beer.rating.average)
		return (
			<BeerStyles>
				<div>{beer.name}</div>
				<img src={beer.image} />
				<div>
					{beer.price} <span>{`⭐`.repeat(rating)}</span>
				</div>
				<div>{beer.rating.reviews} reviews</div>
			</BeerStyles>
		)
	} else {
		return null
	}
}

function BeersList({ beers }) {
	return (
		<BeersStyles>
			{beers.map((beer) => (
				<SingleBeer beer={beer} key={beer.id} />
			))}
		</BeersStyles>
	)
}

export default BeersList
